@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../node_modules/animate.css/animate.min.css';
@import '../node_modules/slick-carousel/slick/slick.css';
@import '../node_modules/slick-carousel/slick/slick-theme.css';

@font-face {
  font-family: quostige;
  src: url('assets/fonts/Quostige.ttf');
}

@font-face {
  font-family: confortaa-regular;
  src: url('assets/fonts/Comfortaa_Regular.ttf');
}

@font-face {
  font-family: confortaa-bold;
  src: url('assets/fonts/Comfortaa_Bold.ttf');
}

@font-face {
  font-family: confortaa-thin;
  src: url('assets/fonts/Comfortaa_Thin.ttf');
}

html {
  min-height: 100%;
  height: 100%;
}

body {
  min-height: 100%;
  height: 100%;
  margin: 0;
  color: rgba(0,0,0,.5);
  font-family: confortaa-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./assets/images/flowersbg.png");
}

#root {
  /*min-height: 100%;*/
  /*height: 100%;*/
  /*display: flex;*/
  /*flex-direction: column;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slide-container {
  /*width: 70%;*/
  position: relative;
  color: white;
  margin: auto; }

h3 {
  text-align: center; }

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  width: 100%;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 100%;
  min-height: 700px;
  overflow: hidden;
  background-size: cover;
  background-attachment: fixed;
  z-index: 0;
}

@media (max-width: 1150px) {
  .each-fade .image-container {
    width: 100%;
    min-height: 300px;
    overflow: hidden;
    background-size: contain;
    background-position: top;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }
}

.image-container-x {
  background-image: url("./assets/images/over.png");
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 1150px) {
  .image-container-x {
    background-image: none;
  }
}

.each-fade .image-container-1 {
  background-image: url("./assets/images/woman1.jpg");
}

.each-fade .image-container-2 {
  background-image: url("./assets/images/woman2.jpg");
}

.each-fade .image-container-3 {
  background-image: url("./assets/images/woman3.jpg");
}

.each-fade .image-container-4 {
  background-image: url("./assets/images/woman4.jpg");
}

@media (max-width: 900px) {
  .each-fade .image-container-1 {
    background-image: url("./assets/images/woman1_mobile.jpg");
  }
  .each-fade .image-container-2 {
    background-image: url("./assets/images/woman2_mobile.jpg");
  }
  .each-fade .image-container-3 {
    background-image: url("./assets/images/woman3_mobile.jpg");
  }
  .each-fade .image-container-4 {
    background-image: url("./assets/images/woman4_mobile.jpg");
  }
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

.MuiPaper-rounded {
  border-radius: 25px !important;
}

.slick-slide div {
  outline: none !important;
}

.slick-prev:before, .slick-next:before {
  color: #cacaca !important;
}

.locked {
  max-height: 100vh;
  overflow: hidden;
}

@media (max-width: 900px) {
  .locked {
    max-height: unset;
    overflow: unset;
  }
}

.dropdown-item.active {
  color: rgba(0,0,0,.5);
  background-color: unset;
}

.Toastify__toast {
  border-radius: 8px !important;
}

.Toastify__toast--error {
  background: #bd463a !important;
  font-family: confortaa-regular !important;
}

.pagination {
  width: 100%;
  justify-content: center;
  transition: all 0.2s ease;
}

.pagination .active {
  color: black;
  font-weight: bold;
  background: rgba(0,0,0,0.1);
  transition: all 0.2s ease;
}

.pagination .disabled {
  color: rgba(0,0,0,0.1);
  border: 1px solid rgba(0,0,0,0.1);
  transition: all 0.2s ease;
}

.pagination > li {
  margin: 3px;
  border-radius: 5px;
  min-width: 30px;
  border: 1px solid rgba(0,0,0,0.3);
  padding: 0 1%;
  font-size: medium;
  text-align: center;
  font-family: confortaa-regular;
  transition: all 0.2s ease;
}

.pagination > li:hover {
  cursor: pointer;
}

.pagination > li > a {
  color: rgba(0,0,0,0.5);
}

/*h1, h2, h3, h4, h5, h6 {*/
/*  color: unset !important;*/
/*  font-family: unset !important;*/
/*}*/

/*a {*/
/*  font-family: confortaa-regular !important;*/
/*}*/

@-webkit-keyframes growing /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes growing {
  0% {
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  80% {
    -ms-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -ms-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }
}
.growing {
  -webkit-animation: growing 1s linear infinite;
  -moz-animation: growing 1s linear infinite;
  -ms-animation: growing 1s linear infinite;
  -o-animation: growing 1s linear infinite;
  animation: growing 1s linear infinite;
}

.MuiAccordionSummary-root {
  border-bottom: 1px solid #cacaca !important;
}

.MuiAccordion-root:before {
  background-color: none !important;
}

.MuiAccordionSummary-content {
  font-size: 15px;
  font-family: montserrat-regular;
  color: #0645AD !important;
  cursor: pointer;
}

.MuiAccordionDetails-root {
  display: flex;
  flex-direction: column;
}
